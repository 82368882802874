export default (
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 12 12"
    enableBackground="new 0 0 12 12"
    id="Слой_1"
    version="1.1"
    className="compass-icon"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    stroke="#fff"
  >
    <g>
      <rect
        fill="#1D1D1B"
        height="1"
        width="11"
        x="0.5"
        y="5.5"
      />

      <rect
        fill="#1D1D1B"
        height="1"
        width="11"
        x="0.5"
        y="2.5"
      />

      <rect
        fill="#1D1D1B"
        height="1"
        width="11"
        x="0.5"
        y="8.5"
      />
    </g>
  </svg>
);
